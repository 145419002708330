<template>
  <div>
    <div class="px-4 sm:px-0">
      <div
        v-if="$device.isDesktop"
        class="text-grayAlpha80 mb-8 text-3xl font-semibold"
      >
        料金シミュレーション
      </div>
      <SectionHeader
        v-else
        text="料金シミュレーション"
        class="mb-6"
      />
      <div class="text-textBlack mb-4 text-sm tracking-wider">
        区分数
      </div>
      <div class="flex items-center">
        <NumberValueStepper
          v-model="klassCount"
          :min="1"
          :max="45"
        />
        <HelpLink
          title="区分とは"
          class="ml-4"
          @click="showsHelpKlass = true"
        />
      </div>
      <div class="mt-4 items-start pb-44 sm:flex sm:pb-0">
        <PriceGroup
          class="border-borderGray rounded-lg border border-solid sm:w-[476px]"
          title="出願費用"
          :disable-expands="true"
          :total="applySubTotalFee"
          :items="[
            { label: `出願手数料（${klassCount}区分）`, price: applyUsageFee },
            { label: '審査スピードアップ', price: speedPlanFee },
            { label: '出願時消費税', price: applyTax },
            { label: '特許印紙代（出願）', price: applyPrintFee },
          ]"
        >
          <template #header>
            <div class="mt-4 flex items-center">
              <BaseCheckbox
                id="additional_options[speed_plan]"
                v-model="speedPlan"
                name="additional_options[speed_plan]"
              />
              <label
                for="additional_options[speed_plan]"
                class="text-grayAlpha60 ml-4 text-sm tracking-wider"
              >
                審査スピードアップオプション
              </label>
            </div>
            <div
              class="bg-primaryOrange5 text-grayAlpha60 mt-2 rounded p-3 text-sm"
            >
              通常6〜9ヶ月の審査を2~3ヶ月で行う、特許庁の早期審査制度を利用します。ご利用には条件があります。
            </div>
          </template>
        </PriceGroup>
        <div
          class="material-icons text-grayAlpha20 my-4 w-full text-center font-bold sm:mx-4 sm:my-0 sm:w-auto sm:pt-20"
          :style="{ fontSize: '40px' }"
        >
          add
        </div>
        <PriceGroup
          class="border-borderGray rounded-lg border border-solid sm:w-[476px]"
          title="登録費用"
          :disable-expands="true"
          :total="registrationSubTotalFee"
          :items="[
            {
              label: `登録手数料（${klassCount}区分）`,
              price: registrationUsageFee,
            },
            { label: '登録時消費税', price: registrationTax },
            { label: '特許印紙代（登録）', price: registrationPrintFee },
          ]"
        >
          <template #header>
            <div class="mt-4 flex items-start justify-between">
              <div class="text-grayAlpha60 text-sm">
                登録年数で変わります。<br>
                詳しくは
                <a
                  class="text-primary underline"
                  href="https://support.cotobox.com/faq-5years-10years/"
                  target="_blank"
                >
                  こちら
                </a>
              </div>
              <RadioButtonGroup
                class="text-grayAlpha60"
                :options="[
                  { label: '5年', value: '5years' },
                  { label: '10年', value: '10years' },
                ]"
                :model-value="registPeriod"
                @update:model-value="
                  ($e) => {
                    registPeriod = $e as '5years' | '10years';
                  }
                "
              />
            </div>
          </template>
        </PriceGroup>
      </div>
    </div>
    <!-- 合計 -->
    <template v-if="$device.isDesktop">
      <div
        class="border-primary mt-8 rounded-2xl border-2 border-solid px-6 py-4 sm:mx-auto sm:w-[480px]"
      >
        <div class="flex items-center justify-between">
          <div class="text-primary text-2xl font-bold tracking-widest">
            合計
          </div>
          <div class="flex items-baseline text-right">
            <div class="text-primary text-3xl font-bold tracking-widest">
              {{ `${totalFee.toLocaleString()}円` }}
            </div>
            <div class="text-primary text-xs">
              （税込）
            </div>
          </div>
        </div>
        <div class="text-grayAlpha60 mt-2 text-sm tracking-wider">
          出願費用と登録費用の合計です。
        </div>
      </div>
      <div class="text-grayAlpha60 mt-2 text-center text-xs">
        ※
        1回の審査で登録を受けられなかった場合など、追加費用が発生する可能性がございます。
      </div>
    </template>
    <template v-else>
      <div
        class="border-borderGray fixed bottom-0 h-36 w-screen border-t border-solid bg-white"
      >
        <div class="px-4 py-4">
          <div class="text-primary mb-2 text-sm">
            出願費用と登録費用の合計です。
          </div>
          <div class="flex items-baseline text-right">
            <div class="text-primary text-3xl font-bold tracking-widest">
              {{ `${totalFee.toLocaleString()}円` }}
            </div>
            <div class="text-primary text-xs">
              （税込）
            </div>
          </div>
          <div class="text-grayAlpha60 mt-2 text-xs tracking-wider">
            ※ 1回の審査で登録を受けられなかった場合など<br>追加費用が発生する可能性がございます。
          </div>
        </div>
      </div>
    </template>
    <!-- ヘルプモーダル: 区分とは -->
    <Modal
      :is-visible="showsHelpKlass"
      @close="showsHelpKlass = false"
    >
      <div class="px-6 pb-8 pt-6">
        <div
          class="border-borderGray border-b border-solid px-1 py-2 text-base font-semibold tracking-wider text-black"
        >
          区分とは／どの区分かわからないとき
        </div>
        <HelpContentKlass />
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, inject } from 'vue';
import NumberValueStepper from '@/components/common/NumberValueStepper.vue';
import HelpLink from '@/components/common/HelpLink.vue';
import HelpContentKlass from '@/components/search/HelpContentKlass.vue';
import Modal from '@/components/common/Modal.vue';
import PriceGroup from '@/components/price/PriceGroup.vue';
import { usePriceCalculator } from '@/composables/usePriceCalculator';
import { UserDataStore } from '@/composables/useUserStore';
import { UserDataKey } from '@/composables/injectionKeys';
import RadioButtonGroup from '@/components/common/RadioButtonGroup.vue';
import BaseCheckbox from '@/components/common/BaseCheckbox.vue';
import SectionHeader from './SectionHeader.vue';

const klassCount = ref(1);
const showsHelpKlass = ref(false);
const speedPlan = ref(false);
const registPeriod = ref<'5years' | '10years'>('5years');

const { state: userState, authenticated } = inject(
  UserDataKey,
) as UserDataStore;

const isPersonal = computed(() => userState.isPersonal);

const {
  applyUsageFee,
  speedPlanFee,
  applyPrintFee,
  applyTax,
  applySubTotalFee,
  registrationUsageFee,
  registrationPrintFee,
  registrationTax,
  registrationSubTotalFee,
  totalFee,
} = usePriceCalculator({
  klassCount,
  premiumPlan: ref(false),
  speedPlan,
  authenticated,
  isPersonal,
  isAttorneyCorporation: ref(true),
  is10yearRegistration: computed(() => registPeriod.value === '10years'),
});
</script>
