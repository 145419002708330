import { Ref, ref, computed, ComputedRef } from 'vue';
import { TrademarkType, KlassId } from '@/types';
import { GoodsService, goodsServices } from '@/constants/goodsServices';
import { GoodsServiceIds } from '@/composables/useKlassList';
import {
  APIClientInterface,
  SimilarResult,
  RegistrationStatus,
  SaveTrademarkSearchQueryParams,
} from '@/api/APIClientInterface';

export type TrademarkSearchProgress =
  | 'not_set'
  | 'loading'
  | 'completed'
  | 'query_changed'
  | 'failed';

/**
 * 検索結果の管理
 * @param klassesForSearch 検索対象の区分
 * @param klassesForSearchGoodsServiceIds 検討中に保存する際の選択区分
 * @description `setTrademarkText()`や`loadImageToken()`などで商標や画像をセットし、`executeTrademarkSearch`を実行すると、検索結果が`textSearchResults`や`imageSearchResults`にセットされる
 */
export const useTrademarkSearch = (
  apiClient: APIClientInterface,
  klassesForSearch: Ref<GoodsServiceIds>,
  klassesForSearchGoodsServiceIds: Ref<string[]> | ComputedRef<string[]>,
) => {
  const rawTrademarkType = ref<TrademarkType>('text');
  const rawStandardCharacter = ref('');
  const rawImageText = ref('');
  const rawInputtedYomi = ref('');
  const rawImageYomi = ref('');
  const rawImageDataURL = ref('');
  const rawImageToken = ref('');
  const rawExistsText = ref(true);

  const trademarkType = computed(() => rawTrademarkType.value);
  const standardCharacter = computed(() => rawStandardCharacter.value);
  const imageText = computed(() => rawImageText.value);
  const inputtedYomi = computed(() => rawInputtedYomi.value);
  const imageYomi = computed(() => rawImageYomi.value);
  const imageDataURL = computed(() => rawImageDataURL.value);
  const imageToken = computed(() => rawImageToken.value);
  const existsText = computed(() => rawExistsText.value);

  function setTrademarkType (value: TrademarkType) {
    rawTrademarkType.value = value;
    if (progress.value === 'completed') {
      progress.value = 'query_changed';
    }
  }

  function setInputtedYomi (value: string) {
    rawInputtedYomi.value = value;
    if (progress.value === 'completed') {
      progress.value = 'query_changed';
    }
  }

  function setImageYomi (value: string) {
    rawImageYomi.value = value;
    if (progress.value === 'completed') {
      progress.value = 'query_changed';
    }
  }

  function setExistsText (value: boolean) {
    rawExistsText.value = value;
    if (progress.value === 'completed') {
      progress.value = 'query_changed';
    }
  }

  const progress = ref<TrademarkSearchProgress>('not_set');
  const textSearchRawResults = ref([] as SimilarResult[]);
  const imageSearchRawResults = ref([] as SimilarResult[]);
  const klassIdsForSearch = computed(
    () => Object.keys(klassesForSearch.value) as KlassId[],
  );

  /** 文字商標検索結果 */
  const textSearchResults = computed(() => {
    return klassIdsForSearch.value.reduce(
      (previousValue, klassId) => {
        const selectedKlassGoodsServiceIds = klassesForSearch.value[klassId]!;
        return {
          ...previousValue,
          [klassId]: selectedKlassGoodsServiceIds
            .map((goodsServiceId) => {
              const similarResult = textSearchRawResults.value.find(
                (similarResult) =>
                  similarResult.goodsServiceId === goodsServiceId,
              );
              if (similarResult) {
                return similarResult;
              } else {
                // 選択した商品役務が検索結果の中に存在しない場合
                return {
                  goodsServiceId,
                  registrationStatus: 'unregistered',
                  similarTextTrademarks: [],
                } as SimilarResult;
              }
            })
            .sort((a, b) => {
              const klassGoodsServices = goodsServices[klassId];
              const goodsServiceIdA = klassGoodsServices[
                a.goodsServiceId
              ] as GoodsService;
              const goodsServiceIdB = klassGoodsServices[
                b.goodsServiceId
              ] as GoodsService;
              return goodsServiceIdA.order - goodsServiceIdB.order;
            }),
        };
      },
      {} as { [key in KlassId]?: SimilarResult[] },
    );
  });

  /** ロゴ商標検索結果 */
  const imageSearchResults = computed(() => {
    return klassIdsForSearch.value.reduce(
      (previousValue, klassId) => {
        const selectedKlassGoodsServiceIds = klassesForSearch.value[klassId]!;
        return {
          ...previousValue,
          [klassId]: selectedKlassGoodsServiceIds
            .map((goodsServiceId) => {
              const similarResult = imageSearchRawResults.value.find(
                (similarResult) =>
                  similarResult.goodsServiceId === goodsServiceId,
              );
              if (!existsText.value) {
                if (similarResult) {
                  return similarResult;
                } else {
                  // 選択した商品役務が検索結果の中に存在しない場合
                  return {
                    goodsServiceId,
                    registrationStatus: 'unregistered',
                    similarImageTrademarks: [],
                  } as SimilarResult;
                }
              } else {
                const similarTextResult = textSearchRawResults.value.find(
                  (similarResult) =>
                    similarResult.goodsServiceId === goodsServiceId,
                );
                if (
                  similarResult === undefined &&
                  similarTextResult !== undefined
                ) {
                  return similarTextResult;
                } else if (
                  similarResult !== undefined &&
                  similarTextResult === undefined
                ) {
                  return similarResult;
                } else if (
                  similarResult === undefined &&
                  similarTextResult === undefined
                ) {
                  // 選択した商品役務が検索結果の中に存在しない場合
                  return {
                    goodsServiceId,
                    registrationStatus: 'unregistered',
                    similarImageTrademarks: [],
                    similarTextTrademarks: [],
                  } as SimilarResult;
                } else if (similarResult != null && similarTextResult != null) {
                  const registrationStatus = registrationStatusWithScore(
                    Math.max(
                      scores[similarResult.registrationStatus],
                      scores[similarTextResult.registrationStatus],
                    ),
                  );
                  return {
                    goodsServiceId,
                    registrationStatus,
                    similarImageTrademarks:
                      similarResult.similarImageTrademarks,
                    similarTextTrademarks:
                      similarTextResult.similarTextTrademarks,
                  } as SimilarResult;
                } else {
                  return {
                    goodsServiceId,
                    registrationStatus: 'unregistered',
                    similarImageTrademarks: [],
                    similarTextTrademarks: [],
                  } as SimilarResult;
                }
              }
            })
            .sort((a, b) => {
              const klassGoodsServices = goodsServices[klassId];
              const goodsServiceIdA = klassGoodsServices[
                a.goodsServiceId
              ] as GoodsService;
              const goodsServiceIdB = klassGoodsServices[
                b.goodsServiceId
              ] as GoodsService;
              return goodsServiceIdA.order - goodsServiceIdB.order;
            }),
        };
      },
      {} as { [key in KlassId]?: SimilarResult[] },
    );
  });

  /** バリデーションや加工を噛ませた上で商標を入力 */
  function setTrademarkText (text: string) {
    const formattedText = text.replace(/'/g, '’').replace(/‘/g, '’');
    if (trademarkType.value === 'text') {
      rawStandardCharacter.value = formattedText;
    } else if (trademarkType.value === 'image') {
      rawImageText.value = formattedText;
    }

    if (progress.value === 'completed') {
      progress.value = 'query_changed';
    }
  }

  /** 選択した画像のデータURLから`imageToken`を発行 */
  async function loadImageToken (file: File) {
    rawImageDataURL.value = URL.createObjectURL(file);
    if (progress.value === 'completed') {
      progress.value = 'query_changed';
    }
    const formData = new FormData();
    formData.append('image_file', file);

    const { data, error } = await apiClient.generateImageToken(formData);

    if (data) {
      rawImageToken.value = data;
    }
    return { data, error };
  }

  /** `imageToken`から画像を表示するためのURLを取得 */
  async function loadImageDataURL (token: string) {
    rawImageToken.value = token;
    if (progress.value === 'completed') {
      progress.value = 'query_changed';
    }

    const { data, error } = await apiClient.fetchImage(imageToken.value);
    if (error) {
      return { error };
    }
    if (data) {
      rawImageDataURL.value = data;
      return { data };
    }
    return { data, error };
  }

  /** 検索API実行 */
  async function executeTrademarkSearch () {
    if (trademarkType.value === 'text') {
      await executeTextTrademarkSearch();
    } else if (trademarkType.value === 'image') {
      await executeImageTrademarkSearch();
    }
  }

  /** 文字検索APIを実行 */
  async function executeTextTrademarkSearch () {
    progress.value = 'loading';
    try {
      const results = await apiClient.searchTextTrademark(
        standardCharacter.value,
        inputtedYomi.value,
      );
      rawInputtedYomi.value = results.yomi;
      textSearchRawResults.value = results.contents;
      progress.value = 'completed';
    } catch (e) {
      progress.value = 'failed';
      throw e;
    }
  }

  /** ロゴ検索APIを実行 */
  async function executeImageTrademarkSearch () {
    progress.value = 'loading';
    try {
      if (existsText.value) {
        const [imageResults, textResults] = await Promise.all([
          apiClient.searchImageTrademark(imageToken.value),
          apiClient.searchTextTrademark(imageText.value, imageYomi.value, true),
        ]);
        imageSearchRawResults.value = imageResults;
        textSearchRawResults.value = textResults.contents;
        rawImageYomi.value = textResults.yomi;
      } else {
        const results = await apiClient.searchImageTrademark(imageToken.value);
        imageSearchRawResults.value = results;
      }
      progress.value = 'completed';
    } catch (e) {
      progress.value = 'failed';
      throw e;
    }
  }

  /** 商標やよみから、URLに付与すべきクエリパラメータを生成 */
  const queryParameter = computed(() => {
    if (trademarkType.value === 'text') {
      return {
        ...(standardCharacter.value && {
          standard_character: standardCharacter.value,
        }),
        ...(inputtedYomi.value && { yomi: inputtedYomi.value }),
      };
    } else {
      return {
        ...(imageToken.value && { image_token: imageToken.value }),
        ...(existsText.value &&
          imageText.value && { standard_character: imageText.value }),
        ...(existsText.value && imageYomi.value && { yomi: imageYomi.value }),
      };
    }
  });

  /** 検討中に保存 */
  async function save () {
    const params: SaveTrademarkSearchQueryParams = {
      trademarkType: trademarkType.value,
      standardCharacter:
        trademarkType.value === 'text'
          ? standardCharacter.value
          : imageText.value,
      inputtedYomi:
        trademarkType.value === 'text' ? inputtedYomi.value : imageYomi.value,
      ...(trademarkType.value === 'image' && { imageToken: imageToken.value }),
      selectedGoodsServiceIds: klassesForSearchGoodsServiceIds.value,
    };

    return await apiClient.saveTrademarkSearchQuery(params);
  }

  function clear () {
    rawTrademarkType.value = 'text';
    rawStandardCharacter.value = '';
    rawImageText.value = '';
    rawInputtedYomi.value = '';
    rawImageYomi.value = '';
    rawImageDataURL.value = '';
    rawImageToken.value = '';
    rawExistsText.value = true;
  }

  return {
    trademarkType,
    standardCharacter,
    imageText,
    inputtedYomi,
    imageYomi,
    imageDataURL,
    imageToken,
    existsText,
    progress,
    textSearchResults,
    imageSearchResults,
    queryParameter,
    setTrademarkType,
    setTrademarkText,
    setInputtedYomi,
    setImageYomi,
    setExistsText,
    loadImageToken,
    loadImageDataURL,
    executeTrademarkSearch,
    save,
    clear,
  };
};

export type TrademarkSearchStore = ReturnType<typeof useTrademarkSearch>;

const scores: { [key in RegistrationStatus]: number } = {
  unregistered: 0,
  potentially_registered: 1,
  registered: 2,
};

function registrationStatusWithScore (score: number) {
  return (
    [
      'unregistered',
      'potentially_registered',
      'registered',
    ] as RegistrationStatus[]
  )[score];
}
