<template>
  <div
    class="border-grayAlpha20 relative h-32 w-32 cursor-pointer border border-solid bg-white bg-contain bg-center bg-no-repeat"
    :style="{ backgroundImage: `url(${imageUrl})` }"
    :data-imagetoken="'FIXME'"
    @click="onClick"
  >
    <div v-if="!imageUrl">
      <span
        class="material-icons material-symbols-outlined text-grayAlpha20 mb-1 mt-8 block w-full text-center"
        style="font-size: 36px"
      >
        photo_camera
      </span>
      <p class="text-grayAlpha20 text-center text-base font-bold">
        画像を選択
      </p>
    </div>
    <input
      ref="input"
      type="file"
      :name="name"
      :accept="accept"
      :style="{ display: 'none' }"
      @change="onFileSelect"
    >
  </div>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from 'vue';

const props = defineProps<{
  imageUrl: string;
  name: string;
  accepts: string[];
  maxSize: number;
}>();

interface Emits {
  (e: 'update:model-value', value: File): void;
  (e: 'error:file-type'): void;
  (e: 'error:file-size'): void;
}

const emit = defineEmits<Emits>();

const accept = computed(() => props.accepts.join(','));
const input = ref(null) as Ref<HTMLElement | null>;

function onClick () {
  if (input.value) {
    input.value.click();
  }
}

function onFileSelect (e: Event) {
  e.preventDefault();

  const fileList = (e.target as HTMLInputElement).files;
  if (!fileList) {
    return;
  }
  const file = fileList[0];

  if (!props.accepts.includes(file.type)) {
    return emit('error:file-type');
  }

  if (file.size > props.maxSize) {
    return emit('error:file-size');
  }

  emit('update:model-value', file);
}
</script>
