<template>
  <!-- PC: 商標登録までの全体の流れ -->
  <SectionBlock
    v-if="$device.isDesktop"
    title="商標登録までの全体の流れ"
    :hides-devider="false"
  >
    <template #header>
      <a
        href="https://support.cotobox.com/process-cotobox"
        class="text-grayAlpha60 flex items-center"
        target="_blank"
      >
        <span class="font-semibold"> 詳細の確認 </span>
        <span class="material-icons material-symbols-outlined">
          navigate_next
        </span>
      </a>
    </template>
    <template #default>
      <div class="flex">
        <TimelineStepper
          class="w-[calc(216px_*_4)]"
          :items="timelineStepperItems"
          :current-step="1"
        />
        <div class="border-grayAlpha80 h-14 w-1 border-r border-dashed pr-6" />
        <TimelineStepper
          class="ml-4 sm:w-[174px]"
          :items="[
            { title: '有効期間', description: '5/10年間有効', fill: false },
          ]"
          :current-step="0"
        />
      </div>
    </template>
  </SectionBlock>
  <!-- スマホ: 商標登録までの全体の流れ -->
  <SectionBlock
    v-if="$device.isMobile"
    title="商標登録までの全体の流れ"
    :hides-devider="true"
    @click:header="showsMobileTimelineStepper = !showsMobileTimelineStepper"
  >
    <template #header>
      <span class="material-icons material-symbols-outlined">
        {{ showsMobileTimelineStepper ? 'expand_less' : 'expand_more' }}
      </span>
    </template>
    <template #default>
      <div
        v-if="showsMobileTimelineStepper"
        class="mt-4"
      >
        <TimelineStepperMobile
          class="mt-4"
          :items="timelineStepperItemsMobile"
          :current-step="1"
        />
        <div class="relative">
          <div class="bg-borderGray mx-auto h-12 w-1" />
          <div
            class="border-grayAlpha80 absolute top-6 w-full border-b border-dashed"
          />
        </div>
        <TimelineStepperMobile
          :items="[
            {
              title: '有効期間',
              fill: true,
              comment: { text: '5/10年間有効' },
            },
          ]"
          :current-step="0"
        />
        <div class="mt-4 flex justify-end">
          <a
            href="https://support.cotobox.com/process-cotobox"
            class="text-grayAlpha60 flex items-center"
            target="_blank"
          >
            <span class="font-semibold"> 詳細の確認 </span>
            <span class="material-icons material-symbols-outlined">
              navigate_next
            </span>
          </a>
        </div>
      </div>
    </template>
  </SectionBlock>
</template>

<script setup lang="ts">
import { ref } from 'vue';
// コンポーネント
import TimelineStepper, {
  TimelineStepperItem,
} from '@/components/search/TimelineStepper.vue';
import TimelineStepperMobile from '@/components/search/TimelineStepperMobile.vue';

const showsMobileTimelineStepper = ref(false);

const timelineStepperItems: TimelineStepperItem[] = [
  {
    title: '類似商標の検索/申込',
    description: '最短数分',
    fill: true,
    comment: { text: 'お支払い（出願費用）', mdiIcon: 'payment' },
  },
  { title: '出願準備〜出願', description: '最短1-2営業日', fill: true },
  {
    title: '審査',
    description: '通常6-9ヶ月',
    fill: true,
    comment: { text: 'お支払い（登録費用）', mdiIcon: 'payment', margin: -184 },
  },
  { title: '登録', description: '約1週間', fill: true },
];

const timelineStepperItemsMobile: TimelineStepperItem[] = [
  {
    title: '類似商標の検索/申込',
    description: '最短数分',
    fill: true,
    comment: { text: 'お支払い（出願費用）', mdiIcon: 'payment' },
  },
  { title: '出願準備〜出願', description: '最短1-2営業日', fill: true },
  { title: '審査', description: '通常6-9ヶ月', fill: true },
  {
    title: '登録',
    description: '約1週間',
    fill: true,
    comment: { text: 'お支払い（登録費用）', mdiIcon: 'payment' },
  },
];
</script>
