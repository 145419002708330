import { reactive, computed } from 'vue';
import { APIClientInterface, PlannedAppyTime, SendConfirmationTokenEmailParams } from '@/api/APIClientInterface';
import { getCookieValue, COOKIE_NAME } from '@/lib/util';

export const useSignUp = (apiClient: APIClientInterface) => {
  const state = reactive<{
    email: string;
    showsEmailError: boolean;
    confirmationToken: string;
    companyName: string;
    isPersonal: boolean;
    industry: string;
    lastName: string;
    lastNameKana: string;
    firstName: string;
    firstNameKana: string;
    phoneNumber: string;
    language: string;
    password: string;
    optIn: boolean;
    channeltalkCustomerId: string;
    userTermsAgreed: boolean;
    currentStep: 1 | 2 | 3;
    loading: boolean;
  }>({
    email: '',
    showsEmailError: false,
    confirmationToken: '',
    companyName: '',
    isPersonal: false,
    industry: 'クラウドサービス・オンラインビジネス',
    lastName: '',
    lastNameKana: '',
    firstName: '',
    firstNameKana: '',
    phoneNumber: '',
    language: 'ja',
    password: '',
    optIn: true,
    channeltalkCustomerId: '',
    userTermsAgreed: false,
    currentStep: 1,
    loading: false,
  });

  const isValidEmail = computed(() => {
    const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    return emailRegex.test(state.email);
  });

  const validateEmail = () => {
    state.showsEmailError = state.email ? !isValidEmail.value : false;
  };

  const mailSendDisabled = computed(() => !state.email || !isValidEmail.value);

  const tokenValidateDisabled = computed(
    () => state.confirmationToken.length !== 6,
  );

  const submittionDisabled = computed(
    () =>
      mailSendDisabled.value ||
      (!state.isPersonal && !state.companyName) ||
      !state.lastName ||
      !state.firstName ||
      !state.industry ||
      !state.language ||
      !state.phoneNumber ||
      !state.password ||
      !state.userTermsAgreed,
  );

  const sendConfirmationMail = async () => {
    state.loading = true;
    const utmSource = await getCookieValue(COOKIE_NAME.UTM_SOURCE);
    const utmMedium = await getCookieValue(COOKIE_NAME.UTM_MEDIUM);
    const utmCampaign = await getCookieValue(COOKIE_NAME.UTM_CAMPAIGN);
    const utmTerm = await getCookieValue(COOKIE_NAME.UTM_TERM);
    const utmContent = await getCookieValue(COOKIE_NAME.UTM_CONTENT);
    const params: SendConfirmationTokenEmailParams = {
      email: state.email,
      language: state.language,
      ruleConfirmed: true,
      utmSource: utmSource ?? undefined,
      utmMedium: utmMedium ?? undefined,
      utmCampaign: utmCampaign ?? undefined,
      utmTerm: utmTerm ?? undefined,
      utmContent: utmContent ?? undefined,
    };

    const { error } = await apiClient.sendConfirmationTokenEmail(params);
    state.loading = false;
    if (error) {
      return error;
    }
  };

  const validateConfirmationToken = async () => {
    state.loading = true;
    const { error } = await apiClient.validateConfirmationToken(
      state.email,
      state.confirmationToken,
    );
    state.loading = false;
    if (error) {
      return error;
    }
  };

  const submit = async () => {
    state.loading = true;
    const phoneNumber = state.phoneNumber.replace(/-/g, '');
    const { error } = await apiClient.signUp({
      companyName: state.companyName,
      email: state.email,
      isPersonal: state.isPersonal,
      industry: state.industry,
      lastName: state.lastName,
      lastNameKana: state.lastNameKana.trim(),
      firstName: state.firstName,
      firstNameKana: state.firstNameKana.trim(),
      phoneNumber,
      password: state.password,
      language: state.language,
      ruleConfirmed: state.userTermsAgreed,
      confirmationToken: state.confirmationToken,
      channeltalkCustomerId: state.channeltalkCustomerId,
    });
    state.loading = false;
    return { error };
  };

  const sendSignupThanksMail = async (plannedApplyTime: PlannedAppyTime) => {
    try {
      await apiClient.sendSignupThanksMail(plannedApplyTime);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    state,
    isValidEmail,
    validateEmail,
    mailSendDisabled,
    sendConfirmationMail,
    tokenValidateDisabled,
    validateConfirmationToken,
    submittionDisabled,
    submit,
    sendSignupThanksMail,
  };
};

export type SignUpStore = ReturnType<typeof useSignUp>;
