<template>
  <div class="bg-white">
    <div class="text-grayAlpha60 mb-3 text-xs font-semibold">
      あなたの取りたい商標、取られる前にまずは検索
    </div>
    <Tabs
      v-model="isImageSearch"
      :options="['テキスト', '画像']"
      class="border-borderGray mb-6 border-b border-solid text-sm"
    />
    <div class="items-center sm:flex">
      <FormTextInput
        v-if="isImageSearch === 0"
        v-model="standardCharacter"
        class="flex-1"
        placeholder="取りたい商標を入力して下さい"
      />
      <div
        v-else
        class="sm:w-[360px]"
      >
        <FilePicker
          name="trademark-image"
          :image-url="imageDataURL"
          :accepts="['image/jpeg']"
          :max-size="5000000"
          @update:model-value="onImageSelect"
          @update:image-url="(url: string) => { imageDataURL = url }"
          @error:file-type="onImageTypeError"
          @error:file-size="onImageSizeError"
        />
        <div class="text-grayAlpha60 mt-2 text-sm">
          ※ 5MB以下、縦横それぞれ1180px以内のjpgファイルを選択してください。
        </div>
      </div>
      <PrimaryButton
        :title="isSubmitting ? '検索中...' : '区分選択して費用を見る'"
        class="mt-2 w-full sm:ml-2 sm:mt-0 sm:h-12 sm:w-auto"
        :disabled="isSubmitting"
        @click="submit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject } from 'vue';
import Tabs from '@/components/common/Tabs.vue';
import FormTextInput from '@/components/common/FormTextInput.vue';
import PrimaryButton from '@/components/common/PrimaryButton.vue';
import FilePicker from '@/components/common/FilePicker.vue';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import { APIClientKey } from '@/composables/injectionKeys';
import { APIClientInterface } from '@/api/APIClientInterface';

const apiClient = inject(APIClientKey) as APIClientInterface;

const toast = useToast();
const router = useRouter();

const isImageSearch = ref<0 | 1>(0);
const standardCharacter = ref('');
const isSubmitting = ref(false);

function onImageTypeError () {
  toast.error('5MB以下のJPEGファイルであることを確認して下さい。');
}

function onImageSizeError () {
  toast.error('5MB以下のJPEGファイルであることを確認して下さい。');
}

async function submit () {
  if (isImageSearch.value === 0) {
    return textSearch();
  } else {
    return imageSearch();
  }
}

function textSearch () {
  router.push({
    name: 'SearchPage',
    query: {
      standard_character: standardCharacter.value,
    },
  });
}

async function imageSearch () {
  if (isSubmitting.value) return;
  isSubmitting.value = true;

  if (!selectedFile.value) {
    isSubmitting.value = false;
    return toast.error('恐れ入りますが、ロゴ画像を再度選択して下さい。');
  }

  const formData = new FormData();
  formData.append('image_file', selectedFile.value);

  try {
    const { data: image_token, error } = await apiClient.generateImageToken(formData);

    if (error || !image_token) {
      throw new Error('画像アップロードに失敗しました');
    }

    await router.push({
      name: 'SearchPage',
      query: {
        image_token,
      },
    });
  } catch (error) {
    toast.error('ロゴ画像のアップロードに失敗しました。\n恐れ入りますが、ブラウザの更新ボタンを押して、もう一度お試しください。');
  } finally {
    isSubmitting.value = false;
  }
}
const imageDataURL = ref<string>('');
const selectedFile = ref<File | null>(null);

function onImageSelect (file: File) {
  selectedFile.value = file;
  imageDataURL.value = URL.createObjectURL(file);
}

</script>
