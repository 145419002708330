<script setup lang="ts">
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { ApplyFormKey } from '@/composables/injectionKeys';
import { ApplyFormStore } from '@/composables/useApplyForm';
import BaseCheckbox from '../common/BaseCheckbox.vue';

const { t } = useI18n({
  messages: {
    ja: {
      premiumPlanTitle: '安心フルサポート \n1区分あたり9,000円(税別)',
      premiumPlanDescription1text:
        '電話やオンラインメッセージにて{0}進めたい方向けのオプションです。',
      premiumPlanDescription1strong: '専門家（弁理士）に質問しながら',
      premiumPlanDescription2:
        'こんな方におすすめ\n' +
        '・選択した区分で合っているか不安\n' +
        '・電話で対応してほしい\n' +
        '・検索結果で「類似の商標あり」が出たため、登録可能性や他の商標への変更について相談したい\n' +
        '・文字で登録するかロゴで登録するか迷っている\n',
      speedPlanTitle: '審査スピードアップ \n1商標あたり30,000円(税別)',
      speedPlanDescription:
        '早期審査を利用すれば、わずか2〜3ヶ月で審査結果がでます。\nご利用には、所定の要件を満たす必要があります。要件は{0}をご覧ください。\nなお、通常の審査期間は、出願から約6〜9ヶ月です。',
      speedPlanDescription1:
        '通常6〜9ヶ月かかる審査を2〜3ヶ月で行うオプションです。特許庁の早期審査制度を利用します。\n' +
        'ご利用には、{0}を満たす必要があります。\n' +
        '要件に該当するかわからない場合、まずはオプションを付けずにお申し込みください。お申し込み後にオプションを追加することも可能です。\n',
      anchorText: '所定の要件',
    },
    en: {
      premiumPlanTitle: 'Anshin Full Support (9,000 yen per category)',
      speedPlanTitle: 'Speed up screening (30,000 yen)',
      speedPlanDescription:
        'If you take advantage of the early screening process, you will receive the results in just 2 to 3 months.\n' +
        'To take advantage of this service, you must meet certain requirements. Please see {0} for requirements.\n' +
        'The normal examination period is approximately 4 to 9 months from the filing date.',
      anchorText:
        'this way (direction close to the speaker or towards the speaker)',
    },
    zh: {
      premiumPlanTitle: '安全全面支持（每类9,000日元）',
      speedPlanTitle: '加速检查（30,000日元）',
      speedPlanDescription:
        '如果你使用早期筛查程序，你可以在短短两到三个月内得到结果。\n' +
        '要利用这项服务，你需要满足某些要求。要求见{0}。\n' +
        '正常情况下，审查期从申请起约为4-9个月。',
      anchorText: '这样(方向靠近扬声器或朝向扬声器)',
    },
  },
});

const { state } = inject(ApplyFormKey) as ApplyFormStore;
</script>

<template>
  <div class="grid gap-y-4">
    <div
      class="block cursor-pointer rounded border-solid"
      :class="
        !state.speedPlan
          ? 'border border-[#bbb] p-6'
          : 'border-primary border-2 p-[23px]'
      "
    >
      <div class="mb-4 flex items-center">
        <BaseCheckbox
          id="additional_options[speed_plan]"
          v-model="state.speedPlan"
          name="additional_options[speed_plan]"
        />
        <label
          for="additional_options[speed_plan]"
          class="ml-[14px] cursor-pointer whitespace-pre-line text-lg font-bold sm:whitespace-nowrap"
        >
          {{ t('speedPlanTitle') }}
        </label>
      </div>
      <label
        for="additional_options[speed_plan]"
        class="cursor-pointer"
      >
        <div class="mt-6 whitespace-pre-line text-sm font-normal leading-6">
          <i18n-t keypath="speedPlanDescription1">
            <a
              class="text-primary underline"
              target="_blank"
              href="https://support.cotobox.com/option-speedup-examination"
            >
              {{ t('anchorText') }}
            </a>
          </i18n-t>
        </div>
        <div class="mb-2 mt-6 text-center font-medium">通常審査</div>
        <div class="flex">
          <div
            class="w-full bg-[#616366] py-2 text-center font-bold text-white"
          >
            審査完了まで6～9ヶ月
          </div>
          <div
            class="border-y-[20px] border-l-[20px] border-solid border-y-[#fff] border-l-[#616366] bg-[#616366]"
          />
        </div>
        <div class="mb-2 mt-8 text-center font-medium">審査スピードアップ</div>
        <div class="flex items-center">
          <div class="mr-2 flex w-2/5">
            <div
              class="bg-primary w-full py-2 text-center font-bold text-white"
            >
              {{ `${$device.isDesktop ? '審査完了まで ' : ''}2～3ヶ月` }}
            </div>
            <div
              class="bg-primary border-l-primary border-y-[20px] border-l-[20px] border-solid border-y-[#fff]"
            />
          </div>
          <div class="w-3/5 border-x-4 border-solid border-[#616366]">
            <div
              class="border-b-4 border-dashed border-[#616366] py-1 text-center font-bold"
            >
              最大7ヶ月の短縮
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
</template>
