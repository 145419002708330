<template>
  <div class="bg-bgGray py-10 sm:py-16 sm:pb-[60px]">
    <SectionHeader
      class="mb-10 sm:pb-14"
      text="利用の具体的な流れ"
    />
    <div class="px-4 sm:mx-auto sm:w-[1121px] sm:px-0">
      <template v-if="$device.isDesktop">
        <TopPageFlowRowDesktop
          v-for="(item, index) in items"
          :key="index"
          :left-text="[1, 4].includes(index)"
          :exists-image="index !== 3"
          :number="index + 1"
          :title="item.title"
          :description="item.description"
          :tasks="item.tasks"
          :image="item.image"
          class="mb-12"
        />
      </template>
      <template v-if="$device.isMobile">
        <TopPageFlowRowMobile
          v-for="(item, index) in items"
          :key="index"
          :exists-image="index !== 3"
          :number="index + 1"
          :title="item.title"
          :description="item.description"
          :tasks="item.tasks.slice(1)"
          class="mb-12"
        />
      </template>
      <div class="flex justify-center">
        <a
          href="https://support.cotobox.com/process-cotobox"
          target="_blank"
        >
          <PrimaryButton
            title="利用の流れを詳しく確認"
            class="w-full sm:mx-auto sm:w-[472px]"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SectionHeader from './SectionHeader.vue';
import TopPageFlowRowDesktop from './TopPageFlowRowDesktop.vue';
import TopPageFlowRowMobile from './TopPageFlowRowMobile.vue';
import Img01 from '@/assets/images/mocks/top-flow-1.png';
import Img02 from '@/assets/images/mocks/top-flow-2.png';
import Img03 from '@/assets/images/mocks/top-flow-3.png';
import Img04 from '@/assets/images/mocks/top-flow-4.png';
import PrimaryButton from '@/components/common/PrimaryButton.vue';

type TopPageFlowItem = {
  title: string;
  description: string;
  tasks: string[];
  image?: string;
};

const items: TopPageFlowItem[] = [
  {
    title: '検索',
    description:
      '似たような商標が取られていないか、確認します。申請書類に記載する商標とカテゴリ（区分）入力も同時に進みます。チャット相談は無料なので、はじめての方も質問しながら操作できます。',
    tasks: ['登録したい商標を入力', '区分を選択'],
    image: Img01,
  },
  {
    title: 'お申し込み',
    description:
      '検索結果から、そのままお申し込みに進めます。見積もりを確認したら、出願人の情報を入力して費用をお支払いください。',
    tasks: [
      '見積書のダウンロード ※PCのみ',
      '出願人情報の入力',
      'オプションの選択',
      '出願費用のお支払い',
    ],
    image: Img02,
  },
  {
    title: '提携弁理士とやりとりし、特許庁へ提出',
    description:
      '担当の提携弁理士が出願書類を整えます。区分や手続きに関するご不明点は、このタイミングでご質問ください。完成した出願書類をマイページで最終チェックしたら、出願手続きは終了です。',
    tasks: ['提携弁理士と相談', '出願書類の最終チェック'],
    image: Img03,
  },
  {
    title: '特許庁での審査',
    description:
      '通常6〜9ヶ月かかります。結果はメール通知されるので、必ずご確認ください。登録許可が降りなかった場合も、追加手続きすると最終的に登録できることがあります。',
    tasks: ['審査結果を待つ'],
  },
  {
    title: '登録料の納付',
    description:
      '登録許可が降りたら、期間内に登録手続きが必要です。マイページで登録年数を選択し、費用をお支払いください。登録料納付の約１週間後に商標権が発生し、手続きは完了です。',
    tasks: ['登録年数の選択', '登録費用のお支払い'],
    image: Img04,
  },
];
</script>
