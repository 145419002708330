import axios from 'axios';
import {
  APIClientInterface,
  ErrorCode,
  GenerateEstimateParams,
  LookupAddressResult,
  NotifyBrowserErrorParams,
  PayBankParams,
  PayParams,
  PlannedAppyTime,
  RegistrationStatus,
  SavedSearchQuery,
  SavedSearchQueryListItem,
  SaveTrademarkSearchQueryParams,
  SendConfirmationTokenEmailParams,
  SignUpParams,
  SimilarImageTrademark,
  SimilarResult,
  SubmitTrademarkApplicationParams,
  TrademarkAdminStatus,
  TrademarkApplication,
  UpdateUserParams,
  UserCreditCard,
} from './APIClientInterface';
import { KlassId } from '@/types';
import { AttorneyOffice, User } from '@/composables/useUserStore';
import {
  HTTPError,
  NotFoundError,
  UnauthorizedError,
  UnknownError,
  StripePaymentError,
} from '@/types/error';
import { computed } from 'vue';

export class APIClient implements APIClientInterface {
  async sendConfirmationTokenEmail (params: SendConfirmationTokenEmailParams) {
    try {
      await axios.post('/app/users/send_confirmation_mail', {
        email: params.email,
        language: params.language,
        rule_confirmed: params.ruleConfirmed,
        utm_source: params.utmSource,
        utm_medium: params.utmMedium,
        utm_campaign: params.utmCampaign,
        utm_term: params.utmTerm,
        utm_content: params.utmContent,
      });
      return {};
    } catch (e) {
      if (
        axios.isAxiosError(e) &&
        e.response?.data.reason === 'account_exists'
      ) {
        const error: ErrorCode = 'already-exists';
        return { error };
      } else {
        const error: ErrorCode = 'internal';
        return { error };
      }
    }
  }

  async validateConfirmationToken (email: string, confirmationToken: string) {
    try {
      await axios.post('/app/users/validate_confirmation_token', {
        email,
        confirmation_token: confirmationToken,
      });
      return {};
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data.reason === 'token_expired'
      ) {
        const error: ErrorCode = 'expired';
        return { error };
      } else {
        const error: ErrorCode = 'not-found';
        return { error };
      }
    }
  }

  async signUp (signUpParams: SignUpParams) {
    try {
      const params = new URLSearchParams();
      params.append('user[company_name]', signUpParams.companyName);
      params.append('user[email]', signUpParams.email);
      params.append('user[is_personal]', String(signUpParams.isPersonal));
      params.append('user[industry]', signUpParams.industry);
      params.append('user[last_name]', signUpParams.lastName);
      params.append('user[last_name_kana]', signUpParams.lastNameKana);
      params.append('user[first_name]', signUpParams.firstName);
      params.append('user[first_name_kana]', signUpParams.firstNameKana);
      params.append('user[phone_number]', signUpParams.phoneNumber);
      params.append('user[password]', signUpParams.password);
      params.append('user[language]', signUpParams.language);
      params.append('user[rule_confirmed]', String(true));
      params.append('user[is_opt_in]', String(true));
      params.append('user[confirmation_token]', signUpParams.confirmationToken);
      params.append(
        'user[channeltalk_customer_id]',
        signUpParams.channeltalkCustomerId,
      );
      await axios.post('/app/users', params);
      return {};
    } catch (e) {
      const error: ErrorCode = 'internal';
      return { error };
    }
  }

  async sendSignupThanksMail (plannedApplyTime: PlannedAppyTime) {
    try {
      await axios.post('/app/api/xmas/mail/send_on_register_survey', {
        planned_application_time: plannedApplyTime,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new UnauthorizedError();
        } else {
          throw new HTTPError(
            error.response?.status,
            error.response?.data.errors[0],
          );
        }
      }
      throw error;
    }
  }

  async getUser () {
    try {
      const { data: response } = await axios.get<UserResponse>(
        '/app/api/xmas/profile',
      );

      const {
        address_city,
        address_country,
        address_prefecture,
        address_street,
        attorney_office_id,
        company_name,
        email,
        first_name,
        industry,
        is_personal,
        language,
        last_name,
        phone_number,
        ref_num_prefix,
        user_id,
        zipcode,
      } = response;

      const data: User & { attorney_office_id: number | undefined } = {
        id: user_id,
        email,
        companyName: company_name || '',
        isPersonal: is_personal,
        lastName: last_name,
        firstName: first_name,
        phoneNumber: phone_number,
        industry,
        language,
        refNumPrefix: ref_num_prefix,
        address: {
          zipcode: zipcode || '',
          country: address_country || '',
          prefecture: address_prefecture || '',
          city: address_city || '',
          street: address_street || '',
        },
        attorney_office_id,
      };

      return { data };
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 401) {
        const error: ErrorCode = 'unauthorized';
        return { error };
      }
      const error: ErrorCode = 'internal';
      return { error };
    }
  }

  async updateUser (params: UpdateUserParams) {
    const payload = computed<UploadUserRequestPayload>(() => {
      const {
        companyName: company_name,
        isPersonal: is_personal,
        lastName: last_name,
        firstName: first_name,
        phoneNumber: phone_number,
        address,
      } = params;

      const {
        country: address_country,
        zipcode,
        prefecture: address_prefecture,
        city: address_city,
        street: address_street,
      } = address;

      return {
        is_personal,
        company_name,
        last_name,
        first_name,
        phone_number,
        address_city,
        address_country,
        address_prefecture,
        address_street,
        zipcode,
      };
    });

    try {
      const { data: response } = await axios.post<UserResponse>(
        '/app/api/xmas/profile',
        payload.value,
      );

      const {
        email,
        last_name,
        first_name,
        zipcode,
        address_city,
        address_street,
        phone_number,
        ref_num_prefix,
        is_personal,
        company_name,
        industry,
        language,
        address_country,
        address_prefecture,
        user_id,
      } = response;

      const data: User = {
        id: user_id,
        email,
        companyName: company_name || '',
        isPersonal: is_personal,
        lastName: last_name,
        firstName: first_name,
        phoneNumber: phone_number,
        industry,
        language,
        refNumPrefix: ref_num_prefix,
        address: {
          zipcode: zipcode || '',
          country: address_country || '',
          prefecture: address_prefecture || '',
          city: address_city || '',
          street: address_street || '',
        },
      };

      return { data };
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 401) {
        const error: ErrorCode = 'unauthorized';
        return { error };
      } else {
        const error: ErrorCode = 'internal';
        return { error };
      }
    }
  }

  async lookupAddress (postalCode: string) {
    try {
      const { data } = await axios.get<GetPostalResponse>(
        `/app/api/postal/${postalCode}`,
      );
      return {
        prefecture: data.address_prefecture,
        city: data.address_city,
        street: data.address_line,
      } as LookupAddressResult;
    } catch (error) {
      throw new Error();
    }
  }

  async searchTextTrademark (standardCharacter: string, yomi?: string) {
    const encodedStandardCharacter = encodeURIComponent(standardCharacter)
      .replace(/'/g, '’')
      .replace(/‘/g, '’');
    const encodedYomi = yomi || '';
    try {
      const { data } = await axios.get<{
        standard_character: string;
        yomi: string;
        contents: {
          goods_service: string;
          registration_status: RegistrationStatus;
          trademarks: {
            name: string;
            application_number: string;
            registration_status: RegistrationStatus;
            score: number;
          }[];
        }[];
      }>(
        `/app/api/xmas/search/mark?standard_character=${encodedStandardCharacter}&is_image=false${
          yomi && `&yomi=${encodedYomi}`
        }`,
      );
      return {
        standardCharacter: data.standard_character,
        yomi: data.yomi,
        contents: data.contents.map((similarResult) => ({
          goodsServiceId: similarResult.goods_service,
          registrationStatus: similarResult.registration_status,
          similarTextTrademarks: similarResult.trademarks.map((trademark) => ({
            name: trademark.name,
            applicationNumber: trademark.application_number,
            registrationStatus: trademark.registration_status,
            score: trademark.score,
          })),
        })),
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errors = error.response?.data.errors as { [x: string]: string[] };
        const message = Object.values(errors)[0][0];
        throw new HTTPError(error.response?.status, message);
      }
      this.notifyBrowserError({
        message: JSON.stringify(error),
        url: window.location.href,
      });
      throw new UnknownError(JSON.stringify(error));
    }
  }

  async generateImageToken (formData: FormData) {
    try {
      const { data } = await axios.post('/app/stored_image/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return { data: data.image_token };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const error: ErrorCode = 'internal';
        return { error };
      }
      this.notifyBrowserError({
        message: JSON.stringify(e),
        url: window.location.href,
      });
      const error: ErrorCode = 'internal';
      return { error };
    }
  }

  async fetchImage (imageToken: string) {
    try {
      const { data } = await axios.get<string>(
        `/app/api/xmas/stored_images/${imageToken}`,
      );
      return { data };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.status === 404) {
          const error: ErrorCode = 'not-found';
          return { error };
        } else if (e.response?.status !== 401) {
          const error: ErrorCode = 'unauthorized';
          return { error };
        } else {
          const error: ErrorCode = 'internal';
          return { error };
        }
      }
      this.notifyBrowserError({
        message: JSON.stringify(e),
        url: window.location.href,
      });
      const error: ErrorCode = 'internal';
      return { error };
    }
  }

  async searchImageTrademark (imageToken: string) {
    try {
      const { data } = await axios.post<{
        response: {
          contents: {
            klass_id: number;
            registration_status: RegistrationStatus;
            sim_code: string;
            simgroup_id: string;
            images: {
              id: string;
              image: string;
              registration_status: RegistrationStatus;
              sim_codes: string[];
            }[];
          }[];
          image_token: string;
        };
      }>('/app/api/v1/pict_arrow/search', { image_token: imageToken });
      return data.response.contents.map(
        (content) =>
          ({
            goodsServiceId: content.simgroup_id,
            registrationStatus: content.registration_status,
            similarImageTrademarks: content.images.map(
              (similarImage) =>
                ({
                  imagePath: similarImage.image,
                  applicationNumber: similarImage.id,
                  registrationStatus: similarImage.registration_status,
                }) as SimilarImageTrademark,
            ),
          }) as SimilarResult,
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errors = error.response?.data.errors as { [x: string]: string[] };
        const message = Object.values(errors)[0][0];
        throw new HTTPError(error.response?.status, message);
      }
      this.notifyBrowserError({
        message: JSON.stringify(error),
        url: window.location.href,
      });
      throw new UnknownError(JSON.stringify(error));
    }
  }

  async filterGoodsServices (keyword: string) {
    try {
      const { data } = await axios.get<string[]>(
        `/app/api/xmas/search/class?keyword=${keyword}`,
      );
      return { data };
    } catch (e) {
      const error: ErrorCode = 'internal';
      return { error };
    }
  }

  async saveTrademarkSearchQuery (params: SaveTrademarkSearchQueryParams) {
    try {
      const {
        trademarkType,
        standardCharacter,
        inputtedYomi,
        imageToken,
        selectedGoodsServiceIds,
      } = params;

      const { data } = await axios.post<string>('/app/api/xmas/search_infos', {
        cotobox_type: `${trademarkType}_type`,
        selected_goods_services: selectedGoodsServiceIds,
        ...(!!standardCharacter && { standard_character: standardCharacter }),
        ...(!!inputtedYomi && { yomi: inputtedYomi }),
        ...(!!imageToken && { image_token: imageToken }),
      });

      return { data };
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data.errors.base.includes('すでに保存されています')) {
          const error: ErrorCode = 'already-exists';
          return { error };
        } else if (e.response?.status === 401) {
          const error: ErrorCode = 'unauthorized';
          return { error };
        }
        const error: ErrorCode = 'internal';
        return { error };
      }
      const error: ErrorCode = 'internal';
      return { error };
    }
  }

  async assignAttorneyOffice () {
    try {
      const { data: response } = await axios.post<AssignAttorneyOfficeResponse>(
        '/app/api/xmas/attorney_office',
      );

      const {
        id,
        is_corporation,
        stripe_user_id,
        terms_url,
        name,
        representative_name,
        en_name,
        en_representative_name,
        zh_name,
        zh_representative_name,
      } = response;

      const data: AttorneyOffice = {
        id,
        isCorporation: is_corporation,
        stripeAccountId: stripe_user_id,
        termsURL: terms_url,
        name: {
          ja: name,
          en: en_name,
          zh: zh_name,
        },
        representative: {
          ja: representative_name,
          en: en_representative_name,
          zh: zh_representative_name,
        },
      };

      return { data };
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 401) {
        const error: ErrorCode = 'unauthorized';
        return { error };
      } else {
        const error: ErrorCode = 'internal';
        return { error };
      }
    }
  }

  async generateEstimate (params: GenerateEstimateParams) {
    try {
      const payload: GenerateEstimateRequestPayload = {
        estimates: {
          payment_type: params.paymentType,
          is_image: params.trademarkType === 'image',
          image_token: params.imageToken,
          standard_character: params.standardCharacter,
          classes: params.klasses.map((e) => ({
            class_id: Number(e.klassId),
            simgroup_id: e.goodsServiceId,
          })),
          klass_count: params.klassCount,
          is_premium_plan: params.premiumPlan,
          is_speed_plan: params.speedPlan,
        },
      };

      const { data } = await axios.post<{ estimate_token: string }>(
        '/app/estimates',
        payload,
      );
      return {
        data: data.estimate_token,
      };
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 401) {
        const error: ErrorCode = 'unauthorized';
        return { error };
      } else {
        const error: ErrorCode = 'internal';
        return { error };
      }
    }
  }

  async fetchUserCreditCard () {
    try {
      const { data } = await axios.get<GetCardLast4DigitResponse>(
        '/app/api/xmas/payments/card_last4',
      );
      if (!data.result) {
        throw new NotFoundError();
      } else {
        const result: UserCreditCard = {
          paymentMethodId: data.payment_method_id,
          cardBrand: data.brand,
          last4Digits: data.last4,
          expires: {
            year: data.exp_year,
            month: data.exp_month,
          },
        };
        return result;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new UnauthorizedError();
        } else {
          throw new HTTPError(
            error.response?.status,
            error.response?.data.errors[0],
          );
        }
      }
      throw error;
    }
  }

  async pay (params: PayParams) {
    try {
      const payload: PayRequestPayload = {
        ...('paymentMethodId' in params && {
          payment_method_id: params.paymentMethodId,
        }),
        ...('paymentIntentId' in params && {
          payment_intent_id: params.paymentIntentId,
        }),
        payment_id: params.paymentId,
        idempotency_key: params.idempotencyKey,
      };

      const { data } = await axios.post<
        | {
            result: boolean;
          }
        | {
            payment_intent_client_secret: string;
            requires_action: boolean;
          }
        | {
            error: string;
          }
      >('/app/api/xmas/payments/pay', payload);

      if ('error' in data) {
        throw new UnknownError(data.error);
      }

      return {
        requiresAction:
          'requires_action' in data ? data.requires_action : false,
        paymentIntentClientSecret:
          'payment_intent_client_secret' in data
            ? data.payment_intent_client_secret
            : '',
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          throw new StripePaymentError(
            error.response.data.error,
          );
        }
        if (error.response?.status === 401) {
          throw new UnauthorizedError();
        }
        if (error.response?.data.errors) {
          const key = Object.keys(error.response?.data.errors)[0];
          throw new HTTPError(
            error.response?.status,
            error.response?.data.errors[key][0],
          );
        }
        throw new HTTPError(
          error.response?.status,
          error.response?.data.errors[0],
        );
      } else if (error instanceof UnknownError) {
        throw error;
      } else {
        this.notifyBrowserError({
          message: JSON.stringify(error),
          url: window.location.href,
        });
        throw new UnknownError(JSON.stringify(error));
      }
    }
  }

  async payBank (params: PayBankParams) {
    try {
      const payload: PayBankRequestPayload = {
        payment_id: params.paymentId,
      };

      const { data } = await axios.post<
        | {
            result: boolean;
          }
        | {
            error: string;
          }
      >('/app/api/xmas/payments/pay_bank', payload);

      if ('error' in data) {
        throw new UnknownError(data.error);
      }

      return {
        result: true,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new UnauthorizedError();
        }
        if (error.response?.data.errors) {
          const key = Object.keys(error.response?.data.errors)[0];
          throw new HTTPError(
            error.response?.status,
            error.response?.data.errors[key][0],
          );
        }
        throw new HTTPError(
          error.response?.status,
          error.response?.data.errors[0],
        );
      } else if (error instanceof UnknownError) {
        throw error;
      } else {
        this.notifyBrowserError({
          message: JSON.stringify(error),
          url: window.location.href,
        });
        throw new UnknownError(JSON.stringify(error));
      }
    }
  }

  async submitTrademarkApplication (params: SubmitTrademarkApplicationParams) {
    try {
      const payload: PostTrademarkApplicationRequestPayload = {
        cotobox_type: `${params.trademarkType}_type`,
        standard_character: params.standardCharacter || '',
        yomi: params.inputtedYomi || '',
        ...(params.imageToken !== undefined && {
          image_token: params.imageToken,
        }),
        selected_goods_services: params.selectedGoodsServiceIds,
        is_premium: params.premiumPlan,
        is_accelerated: params.speedPlan,
        payment_method: 'credit',
        address: {
          zipcode: params.address.zipcode,
          address_country: params.address.country,
          address_prefecture: params.address.prefecture,
          address_city: params.address.city,
          address_street: params.address.street,
          phone_number: params.phoneNumber,
          corrected_address: '',
          is_valid: true,
        },
        q: {
          url: params.url,
          q_1: params.interview[1],
          q_2: params.interview[2],
          q_3: params.interview[3],
          q_4: params.interview[4],
          q_5: params.interview[5],
          q_6: params.interview[6],
          q_7: params.interview[7],
        },
        product_name: params.productName,
        comment: params.comment,
        contract_agreed: params.termsAgreed,
        keywords: [],
      };
      const { data } = await axios.post<PostTrademarkApplicationResponse>(
        '/app/api/xmas/tm_app',
        payload,
      );
      return data.id;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new UnauthorizedError();
        }
        if (error.response?.data.errors) {
          const key = Object.keys(error.response?.data.errors)[0];
          throw new HTTPError(
            error.response?.status,
            error.response?.data.errors[key][0],
          );
        }
        throw new HTTPError(
          error.response?.status,
          error.response?.data.errors[0],
        );
      }
      this.notifyBrowserError({
        message: JSON.stringify(error),
        url: window.location.href,
      });
      throw new UnknownError(JSON.stringify(error));
    }
  }

  async fetchTrademarkApplications () {
    try {
      const { data } = await axios.get<GetTrademarksResponse>(
        '/app/api/xmas/tm_app',
      );

      const results: TrademarkApplication[] = data.map((e) => ({
        id: e.id,
        trademarkType: e.cotobox_type === 'text_type' ? 'text' : 'image',
        ...(e.standard_character && {
          standardCharacter: e.standard_character,
        }),
        storedImageUrl: e.stored_image_url,
        imageURL:
          e.image_url && e.image_url !== '/images/fallback/default.png'
            ? e.image_url
            : e.stored_image_url,
        imageState: e.image_state,
        klassIds: e.classes
          ? e.classes.map((e) => String(e) as KlassId)
          : ([] as KlassId[]),
        referenceNumber: e.reference_number,
        applicationNumber: e.application_number,
        registrationNumber: e.registration_number,
        registrationDate: e.registration_date,
        submissionDate: e.submission_date,
        status: {
          user: e.cotobox_status_user,
          admin: e.cotobox_status_admin,
        },
        existsUnrepliedMessages: e.has_unreplied_message,
      }));
      return results;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new UnauthorizedError();
        } else {
          throw new HTTPError(
            error.response?.status,
            error.response?.data.errors[0],
          );
        }
      }
      throw error;
    }
  }

  async fetchSavedSearchQuery (savedSearchQueryId: string) {
    try {
      const { data } = await axios.get<GetSearchInfoResponse>(
        `/app/api/xmas/search_infos/${savedSearchQueryId}`,
      );
      const result: SavedSearchQuery = {
        id: data.id,
        trademarkType: data.cotobox_type === 'text_type' ? 'text' : 'image',
        standardCharacter: data.standard_character,
        yomi: data.yomi,
        imageText:
          data.cotobox_type === 'image_type'
            ? data.standard_character
            : undefined,
        imageURL: data.image_url,
        imageToken: data.image_token,
        goodsServiceIdArray: data.selected_goods_services,
      };
      return result;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new UnauthorizedError();
        } else {
          throw new HTTPError(
            error.response?.status,
            error.response?.data.errors[0],
          );
        }
      }
      throw error;
    }
  }

  async fetchSavedSearchQueryList () {
    try {
      const { data } = await axios.get<GetSearchInfosResponse>(
        '/app/api/xmas/search_infos',
      );
      const results: SavedSearchQueryListItem[] = data.map((e) => ({
        id: e.id,
        trademarkType: e.cotobox_type === 'text_type' ? 'text' : 'image',
        standardCharacter: e.standard_character,
        imageURL: e.image_url,
        klassIds: e.classes as KlassId[],
        createdDate: e.saved_date,
      }));
      return results;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new UnauthorizedError();
        } else {
          throw new HTTPError(
            error.response?.status,
            error.response?.data.errors[0],
          );
        }
      }
      throw error;
    }
  }

  async deleteSavedSearchQuery (savedSearchQueryId: string) {
    try {
      await axios.delete(`/app/api/xmas/search_infos/${savedSearchQueryId}`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          throw new UnauthorizedError();
        } else {
          throw new HTTPError(
            error.response?.status,
            error.response?.data.errors[0],
          );
        }
      }
      throw error;
    }
  }

  notifyBrowserError (params: NotifyBrowserErrorParams) {
    const { message, url, colno, lineno, filename, stack, data, context } =
      params;
    axios.post('/app/api/v1/js_error_logs', {
      message,
      url,
      ...(colno && { colno }),
      ...(lineno && { colno }),
      ...(filename && { filename }),
      ...(stack && { stack }),
      ...(data && { data }),
      ...(context && { context }),
    });
  }
}

type UserResponse = {
  address_city?: string;
  address_country?: string;
  address_prefecture?: string;
  address_street?: string;
  applicants: string[];
  attorney_office_id?: number;
  attorney_office_name: string | null;
  company_name?: string;
  email: string;
  first_name: string;
  industry: string;
  is_personal: boolean;
  language: 'ja' | 'en' | 'zh';
  last_name: string;
  phone_number: string;
  ref_num_prefix: string;
  user_hash: string;
  user_id: number;
  zipcode?: string;
};

type AssignAttorneyOfficeResponse = {
  en_name: string;
  en_representative_name: string;
  id: number;
  is_corporation: boolean;
  name: string;
  representative_name: string;
  stripe_user_id: string;
  terms_url: string;
  zh_name: string;
  zh_representative_name: string;
};

type GenerateEstimateRequestPayload = {
  estimates: {
    payment_type: 'application' | 'registration_5yr' | 'registration_10yr';
    is_image: boolean;
    image_token: string;
    standard_character: string;
    classes: {
      class_id: number;
      simgroup_id: string;
    }[];
    klass_count: number;
    is_premium_plan: boolean;
    is_speed_plan: boolean;
  };
};

type UploadUserRequestPayload = {
  address_city: string;
  address_country: string;
  address_prefecture: string;
  address_street: string;
  company_name: string | undefined;
  first_name: string;
  is_personal: boolean;
  last_name: string;
  phone_number: string;
  zipcode: string;
};

type GetCardLast4DigitResponse = {
  result: boolean;
  brand: string;
  last4: string;
  exp_year: number;
  exp_month: number;
  payment_method_id: string;
};

type PostTrademarkApplicationRequestPayload = {
  standard_character: string;
  yomi: string;
  image_token?: string;
  selected_goods_services: string[];
  cotobox_type: string;
  is_premium: boolean;
  is_accelerated: boolean;
  payment_method: 'credit';
  address: {
    zipcode: string;
    address_country: string;
    address_prefecture: string;
    address_city: string;
    address_street: string;
    phone_number: string;
    corrected_address: string;
    is_valid: boolean;
  };
  q: {
    url: string;
    q_1: boolean;
    q_2: boolean;
    q_3: boolean;
    q_4: boolean;
    q_5: boolean;
    q_6: boolean;
    q_7: boolean;
  };
  product_name: string;
  comment: string;
  contract_agreed: boolean;
  keywords: string[];
};

type PostTrademarkApplicationResponse = {
  id: number;
  status: string;
  amount: number;
};

type PayRequestPayload = {
  payment_method_id?: string;
  payment_intent_id?: string;
  payment_id: number;
  idempotency_key: number;
};

type PayBankRequestPayload = {
  payment_id: number;
};

type GetTrademarksResponse = {
  id: number;
  cotobox_status_admin: TrademarkAdminStatus;
  cotobox_status_user: string;
  status: 'submitted' | 'registered' | 'cancel_application';
  cotobox_type: string;
  standard_character?: string;
  image_url: string;
  stored_image_url: string;
  image_state: string;
  classes?: number[];
  reference_number: string;
  has_unreplied_message: boolean;
  application_number?: string;
  registration_number?: string;
  submission_date?: string;
  registration_date?: string;
}[];

type GetSearchInfosResponse = {
  id: string;
  cotobox_type: string;
  standard_character?: string;
  image_url?: string;
  classes: string[];
  saved_date: string;
}[];

type GetSearchInfoResponse = {
  id: string;
  cotobox_type: 'text_type' | 'image_type';
  standard_character?: string;
  yomi?: string;
  image_token?: string;
  image_url?: string;
  selected_goods_services: string[];
  keywords: string[];
};

type GetPostalResponse = {
  address_city: string;
  address_line: string;
  address_prefecture: string;
  zipcode_status: string;
};
